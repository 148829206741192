import React from 'react';
import kws from '../images/clients/kws.png';
import geps from '../images/clients/geps.png';
import protergia from '../images/clients/protergia.png';

const clientImage = {
    // height: '10rem',
    width: '80%',
    mixBlendMode: 'colorBurn',
    flexDirection: "column"

}

const Clients = () => {
    return (
        <div className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Testimonials</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    {/* <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Some of our clients.</h2> */}
                </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-2">
                        <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6">
                            <center>

                                <img src={"/c1.png"} alt="client" width={"400px"} />

                                <br />



                                <p>
                                    Elevating our production with unparalleled CAD expertise, the team
                                    ensures our focus stays on manufacturing and delivery. Their commitment
                                    to precision enhances client value. Handling complex technical details
                                    seamlessly, they provide final 3D models for production support. Especially
                                    adept at deciphering the complexities of aerospace legacy drawings, Nikcor
                                    is our go-to for reliable CAD support.
                                    - Mr. Venkateshkumar Managing Director, Creative Eng., Solutions
                                </p>
                            </center>
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <center>
                                <img src={"/c2.png"} alt="client" width={"400px"} />
                                <br />

                                <p>Working with Nikcor Techno Solutions has been an absolute game-changer
                                    for our granite monument factory. Their exceptional understanding and
                                    flawless execution ensure that every drawing is perfect on the first try,
                                    saving us time and hassle. Their prompt delivery and impeccable drawing
                                    standards consistently impress both us and our customers. We highly
                                    recommend Nikcor Techno Solutions for anyone seeking top-tier
                                    drawing services.
                                    - Mr. N.Rajasekar Managing Director, Grandeur Granites</p>
                            </center>
                        </div>




                    </div>
                </div>
            </section>
        </div>
    )
}

export default Clients;