import React from 'react';
import img from '../images/web.svg';
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">We are deeply committed to the growth and success of our clients.</h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-blue-400 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={"/Automotive.svg"} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Automotive Design Solutions</h2>
                                <p className="text-md font-medium">
                                    From concept development to detailed design, we offer comprehensive CAD services for
                                    automotive components and systems, helping you bring your ideas to life efficiently and
                                    effectively</p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-blue-400 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img1" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={"Aerospace.svg"} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Aerospace Engineering</h2>
                                <p className="text-md font-medium">
                                    Our experienced team provides precision-engineered CAD solutions for the aerospace industry,
                                    ensuring compliance with rigorous standards and regulations while optimizing performance and
                                    functionality
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-blue-400 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img2" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={"/Industry.svg"} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">Industrial Design Services</h2>
                                <p className="text-md font-medium">
                                    Whether you're in manufacturing, consumer goods, or any other industrial sector, we offer
                                    customized CAD solutions to enhance your product design process, streamline production, and
                                    drive innovation </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-blue-400 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img3" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={"/SVG/Granite Monument Drawings.svg"} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">Granite Monument Drawings</h2>
                                <p className="text-md font-medium">
                                    Experience clarity and control with our meticulous drawings, allowing you to visualize every
                                    aspect of your monument. We ensure each detail meets your exact specifications, guaranteeing
                                    satisfaction before production begins. With our expertise, your vision transforms into a timeless
                                    masterpiece, crafted with precision and care</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;