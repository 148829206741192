import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import OurValues from '../components/OurValues';
import Mission from '../components/Mission';
import WhyTech from '../components/WhyTech';


const Home = () => {
    return (
        <>
            <Hero />
            <Intro />
            {/* <Mission /> */}
            <OurValues />
            <WhyTech />
            <Services />
            {/* <Portfolio /> */}
            <Clients />
            <Cta />
            <Footer />
        </>

    )
}

export default Home;

